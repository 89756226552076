import { NbMenuItem } from '@nebular/theme';

export const MENU_ITEMS: NbMenuItem[] = [
  {
    title: 'Inicio',
    icon: 'home-outline',
    link: '/site/home',
    home: true,
  },
  {
    title: 'Nosotros',
    icon: 'people-outline',
    link: '/site/nosotros',
  },
  {
    title: 'Contacto',
    icon: 'email-outline',
    link: '/site/contacto',
  },

];
